import React from 'react'
import styled from 'styled-components'

// Components
import Layout from 'components/layout/Layout'
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/Buttons/Button'

const StyledBannerDetail = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  padding: 200px 0 100px 0;
  ${({ theme }) => theme.gradient.primaryGradient};
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};

  & h1 {
    text-align: center;
    font-weight: bold;
  }
`

const NotFoundPage = () => (
  <Layout>
    <StyledBannerDetail>
      <BannerWrapper>
        <div className="container">
          <Content content="<h1>Page not found (404)</h1>" />
        </div>
      </BannerWrapper>
    </StyledBannerDetail>
    <div className="container my-5" style={{ height: '50vh' }}>
      <h2>Oops!</h2>
      <p>
        The page you were looking for was moved or does not exist, select a page
        from the menu above or
      </p>
      <Button to="/">Go to homepage</Button>
    </div>
  </Layout>
)

export default NotFoundPage
